import React from 'react';

import FashionIcon from '../../images/svg/fashion.inline.svg';
import BeautyIcon from '../../images/svg/beauty.inline.svg';
import CpgIcon from '../../images/svg/cpg.inline.svg';
import HomeIcon from '../../images/svg/home.inline.svg';
import WellnessIcon from '../../images/svg/wellness.inline.svg';
import EventsIcon from '../../images/svg/events.inline.svg';
import RealEstateIcon from '../../images/svg/real-estate.inline.svg';
import LocalIcon from '../../images/svg/local.inline.svg';
import SaasIcon from '../../images/svg/saas.inline.svg';

const Profile = () => {
	return (
		<div className="relative overflow-hidden">
			<div id="profile" className="pt-32 absolute top-0" />
			<div className="relative">
				<div className="relative flex items-center justify-center overflow-hidden mt-24 md:my-36">
					<div className="container flex flex-col lg:flex-row items-center justify-center">
						<div className="w-full lg:w-1/2">
							<h2 className="text-center lg:text-left">
								adMixt Client Profile
							</h2>
							<p>
								E-commerce, Omnichannel, and Lead Gen Brands focused on
								profitable scale.
							</p>
						</div>
						<div className="w-full lg:w-1/2 lg:pl-24 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-3 gap-4">
							{boxes &&
								boxes.map((box) => <ServiceBox key={box.key} box={box} />)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const ServiceBox = ({ box }) => {
	return (
		<div className="flex flex-col justify-center items-center text-base text-center m-w-44 h-44 bg-white text-primary-yellow py-4 px-3 sm:px-0">
			{box.icon}
			<div className="text-primary-blue text-sm sm:text-base">{box.title}</div>
		</div>
	);
};

const boxes = [
	{
		key: 'Fashion',
		icon: <FashionIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<FashionIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: <span>Fashion</span>,
	},
	{
		key: 'Beauty',
		icon: <BeautyIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<BeautyIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: <span>Beauty</span>,
	},
	{
		key: 'CPG',
		icon: <CpgIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<CpgIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: <span>CPG</span>,
	},
	{
		key: 'Home',

		icon: <HomeIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<HomeIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: <span>Home</span>,
	},
	{
		key: 'Health and Wellness',
		icon: <WellnessIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<WellnessIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: (
			<span>
				Health and <br className="hidden sm:block" /> Wellness
			</span>
		),
	},
	{
		key: 'Events',
		icon: <EventsIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<EventsIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: <span>Events</span>,
	},
	{
		key: 'Real-estate',
		icon: <RealEstateIcon className="object-contain mb-2 fill-current" />,
		iconModal: (
			<RealEstateIcon className="object-contain mb-2 fill-current h-20 mx-auto" />
		),
		title: 'Real-estate',
	},
	{
		key: 'Local Business & Franchise',
		icon: <LocalIcon className="object-contain fill-current" />,
		iconModal: (
			<LocalIcon className="object-contain fill-current h-20 mx-auto" />
		),
		title: (
			<span>
				Local Business <br className="hidden sm:block" /> & Franchise
			</span>
		),
	},
	{
		key: 'SaaS',
		icon: <SaasIcon className="object-contain fill-current" />,
		iconModal: (
			<SaasIcon className="object-contain fill-current h-20 mx-auto" />
		),
		title: <span>SaaS</span>,
	},
];

export default Profile;

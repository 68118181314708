import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import Header from './header';
import Footer from './footer';

function Layout({ children }) {
	return (
		<>
			<Helmet>
				{/* Facebook Pixel Code */}
				<script>
					{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				document,'script','https://connect.facebook.net/en_US/fbevents.js');

				fbq('init', '1376800512580502');
				fbq('track', "PageView"); `}
				</script>
				<noscript>
					{`
						<img
							height="1"
							width="1"
							style="display:none"
							alt=""
							src="https://www.facebook.com/tr?id=1376800512580502&ev=PageView&noscript=1"
						/>
					`}
				</noscript>

				{/* Encharge  Pixel Code */}
				<script>
					{`!function(){if(!window.EncTracking||!window.EncTracking.started){window.EncTracking=Object.assign({}, window.EncTracking, {queue:window.EncTracking&&window.EncTracking.queue?window.EncTracking.queue:[],track:function(t){this.queue.push({type:"track",props:t})},identify:function(t){this.queue.push({type:"identify",props:t})},started:!0});var t=window.EncTracking;t.writeKey="ZMirqYTiyQDCG77WrvVAfr5TY",t.hasOptedIn=true,t.shouldGetConsent=true,t.hasOptedIn&&(t.shouldGetConsent=!1),t.optIn=function(){t.hasOptedIn=!0,t&&t.init&&t.init()},t.optOut=function(){t.hasOptedIn=!1,t&&t.setOptOut&&t.setOptOut(!0)};var n=function(t){var n=document.createElement("script");n.type="text/javascript",n.async=void 0===t||t,n.src="https://resources-app.encharge.io/encharge-tracking.min.js";var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};"complete"===document.readyState?n():window.attachEvent?window.attachEvent("onload",n):window.addEventListener("load",n,!1)}}();`}
				</script>
				{/* Page visit event */}
				<script>
					{`window.EncTracking.track(
					{
					"name": "page visit - admixt",
					"properties": {}
					}
					);`}
				</script>
				{/* Quiz start event - on Quiz button click */}
				<script>
					{`window.EncTracking.track(
					{
					"name": "quiz started",
					"properties": {}
					}
					);`}
				</script>

				<link
					rel="shortcut icon"
					href="https://admixt.com/corp/favicon.ico?v=2"
					type="image/x-icon"
				/>
			</Helmet>

			<div className="flex flex-col min-h-screen">
				<Header />

				<main className="flex-1 w-full pt-28 lg:pt-0">{children}</main>

				<Footer />
			</div>
		</>
	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;

import React from 'react';

import PostProductionIcon from '../../images/svg/post-production-icon.inline.svg';
import CreativeStrategyIcon from '../../images/svg/creative-strategy-icon.inline.svg';
import FeedIcon from '../../images/svg/feed-mgmt-icon.inline.svg';
import NoContractIcon from '../../images/svg/no-contract-icon.inline.svg';

const Work = () => {
	return (
		<div className="relative overflow-hidden">
			<div className="relative flex items-center justify-center overflow-hidden mb-20 md:mb-40 mt-20 z-10">
				<div className="container flex flex-col items-center justify-center">
					<h2 className="text-center">
						Added Bonus When <br /> Working With adMixt
					</h2>
					<div className="max-w-xl lg:max-w-none mx-auto grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-4 md:px-4 pt-10 text-white text-center text-base">
						<div className="p-4 bg-primary-yellow text-md leading-4 text-center">
							<div className="w-full h-36 md:w-44 md:h-44 flex justify-center mx-auto">
								<PostProductionIcon />
							</div>
							<div style={{ minHeight: '64px' }}>
								Post-production creative services
							</div>
						</div>
						<div className="p-4 bg-primary-yellow text-md leading-4 text-center">
							<div className="w-full h-36 md:w-44 md:h-44 flex justify-center mx-auto">
								<CreativeStrategyIcon />
							</div>
							<div style={{ minHeight: '64px' }}>
								Custom creative strategy sessions
							</div>
						</div>
						<div className="p-4 bg-primary-yellow text-md leading-4 text-center">
							<div className="w-full h-36 md:w-44 md:h-44 flex justify-center mx-auto">
								<FeedIcon />
							</div>
							<div style={{ minHeight: '64px' }}>
								Product feed management with image optimization
							</div>
						</div>
						<div className="p-4 bg-primary-yellow text-md leading-4 text-center">
							<div className="w-full h-36 md:w-44 md:h-44 flex justify-center mx-auto">
								<NoContractIcon />
							</div>
							<div style={{ minHeight: '64px' }}>
								No long-term contracts or up-charging
							</div>
						</div>
					</div>
				</div>
			</div>

			<img
				className="absolute right-0 top-0 bottom-0 w-2/5 md:w-1/5 m-auto -right-20"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
				style={{ transform: 'rotate(195deg)' }}
			/>
			<img
				className="absolute left-0 -bottom-24 w-2/5 md:w-15"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
			/>
		</div>
	);
};

export default Work;

import React from 'react';

const Contact = () => {
	return (
		<div
			id="contact"
			className="pt-36 md:pt-56 pb-10 relative -mt-80 overflow-hidden bg-white"
		>
			<img
				className="absolute left-0 top-0 w-full"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/wave-c04585e938d2ddb1921365765800a17d.png"
				alt=""
			/>
			<img
				className="absolute -left-10 top-0 transform rotate-12 w-2/5 md:w-15"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
			/>
			<div className="container text-center lg:text-left relative z-10">
				<h2>Connect with Us!</h2>
				<p>
					Sign up for a free demo of our attribution reporting system or go
					right into an audit!
				</p>
				<div className="relative overflow-hidden w-full h-54 md:h-33">
					<iframe
						className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
						title="Contact"
						src="https://admixt.com/corp/?action=contact&head=off"
						frameBorder={0}
						allowFullScreen
					/>
				</div>
			</div>
		</div>
	);
};

export default Contact;

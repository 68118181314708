import React, { useState } from 'react';
import { Link } from 'gatsby';

import './navigationMobile.css';

function NavigationMobile() {
	const [isDrawerOpen, setToggleNav] = useState(false);
	const toggleNav = () => setToggleNav(!isDrawerOpen);

	return (
		<div className="fixed right-4 top-8 inline-block lg:hidden lg:ml-auto z-40">
			<button
				type="button"
				onClick={toggleNav}
				className={`nav-drawer-button ${
					isDrawerOpen ? 'nav-drawer-button-open' : ''
				}`}
				aria-label="Main Menu"
			>
				<span />
				<span />
				<span />
				<span />
			</button>

			<div
				className="fixed lg:hidden top-0 h-screen mt-50 pb-70 w-full right-full transition-all duration-200 ease-in-out bg-primary-dark-blue bg-opacity-90 overflow-x-hidden overflow-y-auto flex flex-col justify-center items-center"
				style={{
					transform: isDrawerOpen ? `translateX(100%)` : `translateX(0)`,
				}}
			>
				<Link className="btn btn-mobile" to="#services">
					Services
				</Link>
				<Link className="btn btn-mobile" to="#profile">
					Partners
				</Link>
				<Link className="btn btn-mobile" to="#partners">
					Profile
				</Link>
				<Link className="btn btn-mobile" to="#work">
					Work
				</Link>
				<a
					href="https://blog.admixt.com/"
					rel="noopener noreferrer"
					className="btn btn-mobile"
				>
					Blog
				</a>
				<a
					href="https://admixt.limesurvey.net/831597"
					rel="noopener noreferrer"
					className="btn btn-mobile"
				>
					Quiz
				</a>
				<a
					href="https://admixt.com/corp/?action=login"
					rel="noopener noreferrer"
					className="btn btn-mobile"
				>
					Login
				</a>
				<Link className="btn btn-mobile btn-contact" to="#contact">
					Contact
				</Link>
			</div>
		</div>
	);
}

export default NavigationMobile;

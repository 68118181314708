import React, { useState } from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';

const Modal = ({ className, buttonClassName, size, button, children }) => {
	const [showModal, setShowModal] = useState(false);

	let defaultSize = `max-w-4xl`;
	switch (size) {
		case 'small':
			defaultSize = 'lg:max-w-lg';
			break;
		case 'medium':
			defaultSize = 'lg:max-w-2xl';
			break;
		case 'x-large':
			defaultSize = 'lg:max-w-6xl';
			break;
		default:
			defaultSize = `lg:max-w-4xl`;
	}

	return (
		<>
			<button
				className={buttonClassName}
				type="button"
				onClick={() => setShowModal(true)}
			>
				{button}
			</button>
			<DialogOverlay className="z-40" isOpen={showModal}>
				<DialogContent
					className={`shadow relative max-w-sm sm:max-w-lg md:max-w-2xl ${defaultSize}`}
					aria-label="modal"
				>
					<div className={className}>
						<button
							type="button"
							className="absolute top-0 right-4 focus:outline-none text-4xl text-gray-400"
							onClick={() => setShowModal(false)}
							aria-label="close"
						>
							x
						</button>
						{children}
					</div>
				</DialogContent>
			</DialogOverlay>
		</>
	);
};

export default Modal;

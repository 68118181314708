import React from 'react';

import Modal from '../modal';

import FBMarketDevBadget from '../../images/svg/fb-market-dev-badge.inline.svg';
import FBInnovatorBadge from '../../images/svg/fb-innovator-badge.inline.svg';
import FBAdAPIBadge from '../../images/svg/fb-ad-api-badge.inline.svg';
import FBDiscoveryBadge from '../../images/svg/fb-discovery-badge.inline.svg';
import FBCreativeBadge from '../../images/svg/fb-creative-strategy-professional-badge.inline.svg';
import GooglePartner from '../../images/sections/certifications/google-partner.jpg';
import MicrosoftPartner from '../../images/sections/certifications/microsoft-partner.png';

const Certifications = () => {
	const iconClass = 'inline-block object-contain';
	const partnersClass = `${iconClass} cursor-pointer hover:opacity-60`;

	const FBMarketPartnerBadget = (
		<img
			className={`h-20 w-full ${partnersClass}`}
			alt=""
			src="https://admixt-external.s3.amazonaws.com/gatsby/static/fbp-badges-partner-with-container-0293ef6cc0831ee95eb5f3b019d9c818.png"
			loading="lazy"
		/>
	);

	const FBMarketPartnerBadgetModal = (
		<img
			className={`h-16 ${iconClass}`}
			alt=""
			src="https://admixt-external.s3.amazonaws.com/gatsby/static/fbp-badges-partner-with-container-0293ef6cc0831ee95eb5f3b019d9c818.png"
			loading="lazy"
		/>
	);

	return (
		<div className="relative">
			<div className="relative flex items-center justify-center bg-white pt-24 md:pt-36 pb-96 lg:pb-72">
				<div className="container flex flex-col lg:flex-row justify-center z-10">
					<div className="w-full lg:w-1/2 text-center lg:text-left">
						<h2>Platform Certifications</h2>
						<p>
							We pride ourselves on our expertise. <br />
							No other agency holds all of these badges.
						</p>
						<div className="flex justify-center lg:justify-start mb-8 lg:mb-0">
							<div className="sm:grid sm:grid-cols-3 sm:gap-2 items-center sm:mx-10 md:mx-20 lg:m-0 xl:mr-20">
								<Modal
									className="text-center p-4 sm:p-10 md:p-16"
									size="x-large"
									buttonClassName="w-full sm:w-auto"
									button={
										<FBInnovatorBadge
											className={`w-full h-12 mb-6 sm:mb-0 sm:h-auto ${partnersClass}`}
										/>
									}
								>
									<div className="p-8">
										<div className="mb-4">
											<FBInnovatorBadge className={`w-32 h-20 ${iconClass}`} />
										</div>
										<h2>Innovator of the Year</h2>
										<p>
											In 2015, Facebook awarded adMixt its highest honor,
											singling us out as their most innovative partner. This was
											in recognition of our deep commitment to pushing Facebook
											technology forward.
										</p>
									</div>
								</Modal>
								<a
									href="https://www.google.com/partners/agency?id=9632956601"
									target="_blank"
									rel="noreferrer"
									className="text-center w-2/4 inline-block sm:block sm:w-auto"
								>
									<img
										className={`w-2/4 ${partnersClass}`}
										src={GooglePartner}
										alt=""
										loading="lazy"
									/>
								</a>
								<div className="w-2/4 inline-block sm:block sm:w-auto">
									<img
										className={`w-full ${iconClass}`}
										src={MicrosoftPartner}
										alt=""
										loading="lazy"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full lg:w-1/2 lg:pl-14">
						<div className="grid grid-cols-3 gap-4 lg:mb-16 items-start">
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										{FBMarketPartnerBadget}
										<div className="mt-4">
											Ads Technology <br /> Partner​
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">{FBMarketPartnerBadgetModal}</div>
									<h2>Ad Technology Partner</h2>
									<p>
										We build technology solutions that help our clients scale
										and optimize their performance. The adMixt API-based
										platform sits at the center of our managed services. With
										our proprietary adMixt platform features, adMixt staff can
										do more, more quickly, more accurately than anyone else in
										the industry.
									</p>
								</div>
							</Modal>
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										{FBMarketPartnerBadget}
										<div className="mt-4">
											Agency <br /> Partner​​
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">{FBMarketPartnerBadgetModal}</div>
									<h2>Agency Partner</h2>
									<p>
										This program allows us to extend valuable time saving
										services and support to our clients. This includes Design
										Shop and Solutions Engineering consultations, as well as
										high-priority support channels.
									</p>
								</div>
							</Modal>
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										{FBMarketPartnerBadget}
										<div className="mt-4">
											Commerce <br />
											Partner​​
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">{FBMarketPartnerBadgetModal}</div>
									<h2>Commerce Partner</h2>
									<p>
										We help merchants to maximize online sales across platforms
										via pixel placement, pixel event setup, catalog product feed
										optimization and more.
									</p>
								</div>
							</Modal>
						</div>
						<div className="grid grid-cols-4 gap-0 md:gap-4 text-center items-start">
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										<FBCreativeBadge
											className={`w-3/5 h-36 ${partnersClass}`}
										/>
										<div className="text-xs">
											Certified <br />
											Creative Strategy <br /> Professional
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">
										<FBCreativeBadge className={`h-20 ${iconClass}`} />
									</div>
									<h2>Creative Strategy Professional</h2>
									<p>
										The earning of this badge allows one to master their
										creative strategic skills. This is needed to develop
										insightful creative briefs and increase results on Facebook.
									</p>
								</div>
							</Modal>
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										<FBMarketDevBadget
											className={`w-3/5 h-36 ${partnersClass}`}
										/>
										<div className="text-xs">
											Certified <br />
											Marketing <br /> Developer​​
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">
										<FBMarketDevBadget className={`h-20 ${iconClass}`} />
									</div>
									<h2>Certified Marketing Developer</h2>
									<p>
										Facebook’s most basic certification, the Marketing Developer
										badge identifies technical service providers who are
										confirmed to have expertise implementing, diagnosing, and
										repairing the most important technical integrations.
									</p>
								</div>
							</Modal>
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										<FBAdAPIBadge className={`w-3/5 h-36 ${partnersClass}`} />
										<div className="text-xs">
											Certified <br />
											Advertising <br />
											API Developer​​
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">
										<FBAdAPIBadge className={`h-20 ${iconClass}`} />
									</div>
									<h2>Certified Advertising API Developer</h2>
									<p>
										The most advanced technical certification offered by
										Facebook, API Developers have demonstrated expertise with
										Facebook’s most sophisticated technologies for ensuring
										signal integrity.
									</p>
								</div>
							</Modal>
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={
									<>
										<FBDiscoveryBadge
											className={`w-3/5 h-36 ${partnersClass}`}
										/>
										<div className="text-xs">
											Discovery <br />
											Commerce
											<br />
											Specialist
										</div>
									</>
								}
							>
								<div className="p-8">
									<div className="mb-4">
										<FBDiscoveryBadge className={`h-20 ${iconClass}`} />
									</div>
									<h2>Discovery Commerce Specialist</h2>
									<p>
										This badge gives certified learners an opportunity to expand
										their skill set in Discovery Commerce. This includes
										mastering the skills to help deliver personalized
										experiences, relevant &amp; engaging creative, frictionless
										purchases and impactful measurement on Facebook.
									</p>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Certifications;

import React from 'react';

import VideoLoader from '../video';
import AdmixtLogo from '../../images/svg/admixt-logo-blue.inline.svg';

const AgencySoulmate = () => {
	const useWindowSize = () => {
		const isSSR = typeof window !== 'undefined';
		const [windowSize, setWindowSize] = React.useState({
			width: isSSR ? window.innerWidth : '',
			height: isSSR ? window.innerHeight : '',
		});

		function changeWindowSize() {
			setWindowSize({ width: window.innerWidth, height: window.innerHeight });
		}

		React.useEffect(() => {
			window.addEventListener('resize', changeWindowSize);

			return () => {
				window.removeEventListener('resize', changeWindowSize);
			};
		}, []);

		return windowSize;
	};

	const { width } = useWindowSize();

	return (
		<div className="relative bg-white lg:bg-none">
			<div className="relative flex items-center justify-center overflow-hidden">
				<img
					className="hidden lg:block w-screen"
					alt=""
					src="https://admixt-external.s3.amazonaws.com/gatsby/static/banner-c2ff1545f6a693758a0d056419589a1b.jpg"
					loading="lazy"
				/>
				<div className="relative lg:absolute w-full z-10 pt-16">
					<div className="flex flex-col lg:flex-row items-center justify-center">
						<div className="w-full lg:w-2/4">
							{width >= 1024 && (
								<div className="lg:max-w-xs-3 xl:max-w-xs-2 lg:float-right relative overflow-hidden">
									<VideoLoader
										videoSrc="https://admixt-external.s3.amazonaws.com/gatsby/static/Admixt_Reel_Layout_v17-7ac950237a9df934006a12766021a858.mp4"
										playsInline
										muted
										loop
										className="flex relative m-auto w-full h-full"
										style={{
											borderRadius: '10%',
										}}
									/>
								</div>
							)}
						</div>
						<div className="w-11/12 lg:w-3/4 lg:pl-20 xl:pl-40 text-center lg:text-left">
							<AdmixtLogo className="hidden lg:block object-contain w-2/6 mx-auto lg:m-0" />
							<h2 className="text-primary-yellow mt-4">
								A Trustworthy
								<br /> Advertising Partner
							</h2>
							<span className="block text-lg md:text-xl mt-2 mb-14 md:mb-24">
								We are not your average agency.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgencySoulmate;

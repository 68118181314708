import React from 'react';

import LuxuryIcon from '../../images/svg/luxury-icon.inline.svg';
import CountryIcon from '../../images/svg/country-icon.inline.svg';
import RetailIcon from '../../images/svg/retail-icon.inline.svg';
import FunnelIcon from '../../images/svg/funnel-icon.inline.svg';
import SocialSearchIcon from '../../images/svg/social-search-icon.inline.svg';
import HighGrowthIcon from '../../images/svg/high-growth-icon.inline.svg';

const Experience = () => {
	return (
		<div className="relative overflow-hidden">
			<div className="relative z-10">
				<div className="relative flex items-center justify-center overflow-hidden mt-24 md:my-36">
					<div className="container flex flex-col lg:flex-row items-center justify-center">
						<div className="w-full text-center">
							<h2 className="text-3xl sm:text-4xl">
								adMixt Has Years Of <br className="hidden sm:block" />{' '}
								Experience Managing:
							</h2>
							<div className="pt-4 w-full lg:w-2/3 lg:mx-auto">
								<div className="grid grid-cols-3 gap-2 md:gap-4">
									{tabs &&
										tabs.map((tab) => (
											<div key={tab.key} className="block p-0 m-0 border-none">
												<IconBox box={tab} />
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<img
				className="absolute right-0 top-0 bottom-0 w-2/5 md:w-1/5 m-auto -right-20"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
				style={{ transform: 'rotate(195deg)' }}
			/>
			<img
				className="absolute left-0 -bottom-24 w-2/5 md:w-15"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
			/>

			<div id="work" className="absolute bottom-0" />
		</div>
	);
};

const IconBox = ({ box }) => {
	return (
		<div className="flex flex-col justify-around sm:justify-center items-center text-base text-center m-w-44 h-44 bg-white text-primary-yellow py-4">
			{box.icon}
			<div className="text-primary-blue text-xs sm:text-base">{box.title}</div>
		</div>
	);
};

const tabs = [
	{
		key: 'luxery',
		title: (
			<span>
				Luxury <br /> Brands
			</span>
		),
		icon: (
			<LuxuryIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
	{
		key: 'country',
		title: (
			<span>
				Multi-country <br /> Activation Accounts
			</span>
		),
		icon: (
			<CountryIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
	{
		key: 'retail',
		title: (
			<span>
				Retail Store <br /> Initiatives
			</span>
		),
		icon: (
			<RetailIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
	{
		key: 'funnel',
		title: (
			<span>
				Full-funnel <br /> Investments
			</span>
		),
		icon: (
			<FunnelIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
	{
		key: 'social-search',
		title: (
			<span>
				Cohesive Social and <br className="hidden sm:block" /> Search Creative
				Strategies
			</span>
		),
		icon: (
			<SocialSearchIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
	{
		key: 'goals',
		title: (
			<span>
				High-growth <br /> Goals
			</span>
		),
		icon: (
			<HighGrowthIcon className="object-contain mb-2 fill-current h-2/4 sm:h-full mb-2 sm:mb-0" />
		),
	},
];

export default Experience;

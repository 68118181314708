import React from 'react';

import Modal from '../modal';

import OnePercentLogo from '../../images/svg/one-percent-logo.inline.svg';
import ClimateNeutralLogo from '../../images/svg/climate-neutral-logo.inline.svg';

const MissionPartners = () => {
	const iconClass = 'object-contain mx-auto';
	const partnersClass = `${iconClass} w-full cursor-pointer hover:opacity-60`;

	return (
		<div className="relative flex items-center justify-center bg-white pt-24 md:pt-36">
			<div className="container flex flex-col lg:flex-row justify-center">
				<div className="w-full lg:w-1/2">
					<div
						className="text-center lg:text-left mx-auto"
						style={{ maxWidth: '549px' }}
					>
						<h2>Mission Partners</h2>
						<p>
							We backup what we say and believe in with action. It is critical
							for us to support causes and missions we know are important.
						</p>
					</div>
				</div>
				<div className="w-full lg:w-1/2 lg:pl-14 max-w-xl lg:max-w-none mx-auto">
					<div className="grid grid-cols-2 gap-4">
						<div className="flex justify-center items-center">
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={<OnePercentLogo className={partnersClass} />}
							>
								<div className="p-8">
									<div className="mb-4">
										<OnePercentLogo className={iconClass} />
									</div>
									<h2>1% For The Planet</h2>
									<p>
										Businesses that join 1% for the Planet commit to giving 1%
										of gross sales each year to approved nonprofit partners. We
										are proud to join their mission and help...
									</p>
								</div>
							</Modal>
						</div>
						<div className="flex justify-center items-center">
							<Modal
								className="text-center p-4 sm:p-10 md:p-16"
								size="x-large"
								button={<ClimateNeutralLogo className={partnersClass} />}
							>
								<div className="p-8">
									<div className="mb-4">
										<ClimateNeutralLogo className={iconClass} />
									</div>
									<h2>Climate Neutral</h2>
									<p>
										Since our inception, we&apos;ve held an annual “charity
										drive” where we crowdsource suggestions from our network of
										clients, partners, and friends on how we should divide up
										our charitable giving. Since 2020 we&apos;re proud to commit
										to this ongoing effort and join over 3,000 other businesses
										giving 1% for the Planet.
									</p>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MissionPartners;

import React from 'react';

const VideoLoader = ({
	videoSrc,
	videoPoster,
	ccTrack,
	className,
	style,
	playsInline,
	muted,
	loop,
	controls,
}) => {
	if (!videoSrc) return null;
	return (
		<video
			poster={videoPoster}
			className={className}
			autoPlay
			controls={controls || false}
			playsInline={playsInline || false}
			muted={muted || false}
			loop={loop || false}
			style={style}
		>
			<source src={videoSrc} type="video/mp4" />
			<track label="English" kind="captions" srcLang="en-us" src={ccTrack} />
		</video>
	);
};

export default VideoLoader;

import React from 'react';
import { Link } from 'gatsby';

import Logo from '../images/svg/admixt-logo.inline.svg';

import Facebook from '../images/footer/facebook.png';
import Instagram from '../images/footer/instagram.png';
import Twitter from '../images/footer/twitter.png';
import LinkedIn from '../images/footer/linkedin.png';

function Footer() {
	return (
		<footer className="flex justify-center bg-gradient-to-b from-secondary-dark-teal to-primary-dark-blue px-8">
			<div className="container py-8">
				<div className="flex flex-col md:flex-row items-center">
					<Link aria-label="home" to="/">
						<Logo className="object-contain mx-auto md:mx-0 w-3/5" />
					</Link>

					<div className="inline-block mt-2 md:mt-0 md:ml-auto">
						<a
							href="https://admixt.com/?action=privacy"
							rel="noopener noreferrer"
							className="inline-block hover:opacity-75 text-white align-bottom h-8 pl-4"
						>
							Privacy
						</a>
						<a
							href="https://admixt.com/?action=terms"
							rel="noopener noreferrer"
							className="inline-block hover:opacity-75 text-white align-bottom h-8 pl-4"
						>
							Terms
						</a>
						<a
							href="https://www.linkedin.com/company/admixt/jobs/"
							rel="noopener noreferrer"
							className="inline-block hover:opacity-75 text-white align-bottom h-8 pl-4"
						>
							Jobs
						</a>
						<br className="display md:hidden" />
						<a
							className="inline-block pl-4 hover:opacity-75"
							href="https://www.facebook.com/admixt"
						>
							<img src={Facebook} alt="" loading="lazy" />
						</a>
						<a
							className="inline-block pl-4 hover:opacity-75"
							href="https://www.instagram.com/tryadmixt/"
						>
							<img src={Instagram} alt="" loading="lazy" />
						</a>
						<a
							className="inline-block pl-4 hover:opacity-75"
							href="https://twitter.com/adMixt?s=20"
						>
							<img src={Twitter} alt="" loading="lazy" />
						</a>
						<a
							className="inline-block pl-4 hover:opacity-75"
							href="https://www.linkedin.com/company/admixt/"
						>
							<img src={LinkedIn} alt="" loading="lazy" />
						</a>
					</div>
				</div>
				<div className="text-sm lg:text-lg mt-4" style={{ color: '#8B9BBB' }}>
					© Copyright 2012 - 2023 @adMixt.Inc. All rights reserved.
				</div>
			</div>
		</footer>
	);
}

export default Footer;

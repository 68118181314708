import React from 'react';

import IconYellowArrow from '../../images/sections/vow/icon_yellow_arrow.png';

function Vow() {
	return (
		<div className="relative">
			<div className="flex items-center justify-center overflow-hidden">
				<img
					className="hidden sm:block w-screen -m-0.5"
					alt=""
					src="https://admixt-external.s3.amazonaws.com/gatsby/static/banner-031f5d69d4018cfc8aee8451e2ae709f.jpg"
					loading="lazy"
				/>
				<div className="relative sm:absolute w-full z-10">
					<div className="container flex flex-col sm:flex-row items-center justify-center">
						<div className="w-full sm:w-1/2 text-center sm:text-left pt-20 sm:pt-0">
							<h2>Why adMixt</h2>
							<p className="text-lg md:text-xl">
								We are your strategic business partner.
							</p>
							<a
								href="#contact"
								rel="noopener noreferrer"
								className="btn btn-lg btn-yellow block mt-4 lg:mt-24 inline-block"
							>
								Let&apos;s Chat
							</a>
						</div>
						<div className="relative w-full sm:w-1/2 my-4 sm:my-0 text-left">
							<span className="hidden sm:block absolute left-1.5 lg:left-2.5 w-1 bg-primary-blue h-90 md:h-85 lg:h-120 xl:h-135 -top-4 md:-top-2 lg:-top-36 xl:-top-56 3xl:h-200 3xl:-top-106">
								<span
									className="hidden sm:block absolute bg-no-repeat bg-contain mt-1 ml-0.5 w-16 h-16 -left-8 -top-16 lg:w-20 lg:h-20 lg:-left-10 lg:-top-20"
									style={{
										backgroundImage: `url("${IconYellowArrow}")`,
									}}
								/>
							</span>
							<ul className="list-disc sm:list-none relative sm:text-sm md:text-lg xl:text-2xl m-0 px-10 sm:pr-0 sm:pl-10 md:pl-20 lg:pl-24">
								<li className="py-2 lg:py-6">
									<span className="hidden sm:block absolute left-0 my-2 w-4 h-4 lg:w-6 lg:h-6 rounded-full bg-primary-blue" />
									We focus on hitting client goals, measured by their source of
									truth.
								</li>
								<li className="py-2 lg:py-6">
									<span className="hidden sm:block absolute left-0 my-2 w-4 h-4 lg:w-6 lg:h-6 rounded-full bg-primary-blue" />
									We scale clients profitably through consistent, long-term
									success.
								</li>
								<li className="py-2 lg:py-6">
									<span className="hidden sm:block absolute left-0 my-2 w-4 h-4 lg:w-6 lg:h-6 rounded-full bg-primary-blue" />
									We maximize signal volume and health to work from the most
									accurate optimization data possible.
								</li>
								<li className="py-2 lg:py-6">
									<span className="hidden sm:block absolute left-0 my-2 w-4 h-4 lg:w-6 lg:h-6 rounded-full bg-primary-blue" />
									Our technology gives you an advantage over your competitors.
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Vow;

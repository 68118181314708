import React from 'react';

import MetaIcon from '../../images/svg/meta.inline.svg';
import SnapchatIcon from '../../images/svg/snapchat.inline.svg';
import TiktokIcon from '../../images/svg/tiktok.inline.svg';
import PinterestIcon from '../../images/svg/pinterest.inline.svg';

import LinkedIn from '../../images/svg/linkedin.inline.svg';
import GoogleIcon from '../../images/svg/google-icon.inline.svg';
import MicrosoftSuiteIcon from '../../images/svg/microsoft-suite.inline.svg';
import AmazonIcon from '../../images/svg/amazon.inline.svg';

import BigQuery from '../../images/svg/bigquery.inline.svg';
import Google from '../../images/svg/google.inline.svg';
import Shopify from '../../images/svg/shopify.inline.svg';
import NorthBeam from '../../images/svg/northbeam.inline.svg';
import Rockerbox from '../../images/svg/rockerbox.inline.svg';
import Looker from '../../images/svg/looker.inline.svg';
import Woo from '../../images/svg/woo.inline.svg';
import CustomSheets from '../../images/svg/custom-sheets.inline.svg';

const PartnersPlatforms = () => {
	return (
		<div className="relative">
			<div className="relative flex items-center justify-center overflow-hidden lg:py-16 lg:bg-white">
				<div className="w-full lg:container flex flex-col lg:flex-row items-center lg:items-start justify-center">
					<div className="w-full bg-white text-center lg:text-left lg:w-1/2 lg:pr-4 py-16 lg:py-0">
						<div className="mx-auto px-3 sm:px-0" style={{ maxWidth: '549px' }}>
							<h2>
								Ad <br className="hidden lg:block xl:hidden" /> Networks
							</h2>
							<p
								className="px-5 sm:px-0 text-left sm:text-center"
								style={{ minHeight: '140px' }}
							>
								We build deep relationships with every ad network to ensure we
								provide expert-level insights, and measurable results, specific
								to your brand.
							</p>
							<div className="grid grid-cols-4 ml-0 sm:-ml-4 gap-2 sm:gap-0 mt-6">
								{partners &&
									partners.map((partner) => (
										<button
											type="button"
											key={partner.title}
											className="flex justify-center cursor-default"
										>
											{partner.button}
										</button>
									))}
							</div>
							<div className="grid grid-cols-4 ml-0 sm:-ml-4 gap-2 sm:gap-0 mt-6">
								{partners2 &&
									partners2.map((partner2) => (
										<button
											type="button"
											key={partner2.title}
											className="flex justify-center cursor-default"
										>
											{partner2.button}
										</button>
									))}
							</div>
						</div>
					</div>
					<div className="hidden lg:block w-2 h-full absolute bg-white-gray top-0" />
					<div className="w-full bg-white text-center lg:text-left lg:w-1/2 lg:pl-14 py-16 lg:py-0">
						<div className="max-w-xl lg:max-w-none mx-auto px-3 sm:px-0">
							<h2>Data Integrations</h2>
							<p
								className="px-5 sm:px-0 text-left sm:text-center"
								style={{ minHeight: '140px' }}
							>
								Digital marketing is an ecosystem. We are fully integrated with
								the platforms you need, to see the big picture, and the granular
								details. (Don&apos;t see your platform? Let us know and
								we&apos;ll add the integration)
							</p>
							<div className="grid grid-cols-2 sm:grid-cols-3 ml-0 sm:-ml-4 gap-4 sm:gap-4 mt-6">
								{platforms &&
									platforms.map((platform) => (
										<button
											type="button"
											key={platform.title}
											className="flex justify-center items-center flex-col cursor-default text-base"
										>
											{platform.button}
											<div>{platform.title}</div>
										</button>
									))}
							</div>
							<div className="grid grid-cols-2 sm:grid-cols-3 ml-0 sm:-ml-4 gap-4 sm:gap-4 mt-6">
								{platforms2 &&
									platforms2.map((platform2) => (
										<button
											type="button"
											key={platform2.title}
											className="flex justify-center items-center flex-col cursor-default text-base"
										>
											{platform2.button}
											<div>{platform2.title}</div>
										</button>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const partners = [
	{
		button: <MetaIcon />,
		title: 'Meta',
	},
	{
		button: <SnapchatIcon />,
		title: 'Snapchat',
	},
	{
		button: <TiktokIcon />,
		title: 'TikTok',
	},
	{
		button: <PinterestIcon />,
		title: 'Pinterest',
	},
];

const partners2 = [
	{
		button: <LinkedIn />,
		title: 'LinkedIn',
	},
	{
		button: <GoogleIcon />,
		title: 'Google',
	},
	{
		button: <MicrosoftSuiteIcon />,
		title: 'Microsoft Suite',
	},
	{
		button: <AmazonIcon />,
		title: 'Amazon',
	},
];

const platforms = [
	{
		button: <BigQuery />,
		title: 'BigQuery',
	},
	{
		button: <Google />,
		title: 'Google',
	},
	{
		button: <Shopify />,
		title: 'Shopify',
	},
	{
		button: <NorthBeam />,
		title: 'NorthBeam',
	},
	{
		button: <Rockerbox />,
		title: 'Rockerbox',
	},
	{
		button: <Looker />,
		title: 'Looker',
	},
];

const platforms2 = [
	{
		button: <Woo />,
		title: 'WooCommerce',
	},
	{
		button: <CustomSheets />,
		title: 'Custom sheets and files, and more by request',
	},
];

export default PartnersPlatforms;

import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';

import IntegrationIcon from '../../images/svg/integration-icon.inline.svg';
import CreativeIcon from '../../images/svg/creative-icon.inline.svg';
import ReportingIcon from '../../images/svg/reporting-icon.inline.svg';

const Service = () => {
	const [tabIndex, setTabIndex] = React.useState(0);

	const handleTabsChange = (index) => {
		setTabIndex(index);
	};

	return (
		<div className="relative overflow-hidden">
			<div>
				<div id="services" className="pt-32 absolute top-0" />
				<div className="relative z-10">
					<div className="relative flex items-center justify-center overflow-hidden mt-20 mb-24 md:my-36">
						<div className="container flex flex-col lg:flex-row items-center justify-center">
							<div className="w-full text-center">
								<h2 className="text-3xl sm:text-4xl">
									How Our Proprietary Technology
									<br className="hidden md:block" /> Impacts Your Business
								</h2>
								<Tabs
									className="pt-4 w-full lg:w-2/3 lg:mx-auto"
									index={tabIndex}
									onChange={handleTabsChange}
								>
									<TabList className="grid grid-cols-3 gap-2 sm:gap-4">
										{tabs &&
											tabs.map((tab) => (
												<Tab
													key={tab.key}
													className="block p-0 m-0 border-none"
												>
													<IconBox box={tab} display={tabIndex} />
												</Tab>
											))}
									</TabList>
									<TabPanels className="pb-0 pt-8">
										{tabs &&
											tabs.map((tab) => (
												<TabPanel key={`service_tab_${tab.key}`}>
													{tab.content}
												</TabPanel>
											))}
									</TabPanels>
								</Tabs>
								<a
									href="#contact"
									rel="noopener noreferrer"
									className="btn btn-lg btn-yellow block mt-4 inline-block"
								>
									FREE DEMO
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<img
				className="absolute right-0 top-0 bottom-0 w-2/5 md:w-1/5 m-auto -right-20"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
				style={{ transform: 'rotate(195deg)' }}
			/>
			<img
				className="absolute left-0 -bottom-24 w-2/5 md:w-15"
				src="https://admixt-external.s3.amazonaws.com/gatsby/static/radial-dots-9b51b982e0fc18842ec31cfe143f3beb.svg"
				alt=""
				loading="lazy"
			/>

			<div id="partners" className="pb-10 absolute bottom-0" />
		</div>
	);
};

const IconBox = ({ box, display }) => {
	return (
		<div
			className={`service-icons flex flex-col justify-center items-center text-base text-center m-w-44 h-44 
				${
					display === box.tab
						? 'bg-primary-yellow text-white hover:bg-opacity-80'
						: 'bg-white text-primary-yellow hover:bg-primary-yellow hover:text-white'
				}
				 py-4 px-4 sm:px-0`}
		>
			{box.icon}
			<div className={display === box.tab ? 'text-white' : 'text-primary-blue'}>
				{box.title}
			</div>
		</div>
	);
};

const tabs = [
	{
		key: 'integration',
		tab: 0,
		title: (
			<span>
				Attribution <br /> Integrations
			</span>
		),
		icon: <IntegrationIcon className="object-contain mb-2 fill-current" />,
		content: (
			<div style={{ minHeight: '80px' }}>
				<p>
					adMixt is not an attribution tool. We are an attribution integration
					tool. Your first party data is our Source of Truth.
				</p>
			</div>
		),
	},
	{
		key: 'creative',
		tab: 1,
		title: (
			<span>
				Creative <br /> Technology
			</span>
		),
		icon: <CreativeIcon className="object-contain mb-2 fill-current" />,
		content: (
			<div style={{ minHeight: '80px' }}>
				<p>
					Instantly view creative themes that can easily be shared with your
					team to drive creative iteration.
				</p>
			</div>
		),
	},
	{
		key: 'reporting',
		tab: 2,
		title: (
			<span>
				Reporting <br /> Technology
			</span>
		),
		icon: <ReportingIcon className="object-contain mb-2 fill-current" />,
		content: (
			<div style={{ minHeight: '80px' }}>
				<p>
					Access instant, real-time reporting within admixt with fully
					customizable views.
				</p>
			</div>
		),
	},
];

export default Service;

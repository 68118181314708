import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AgencySoulmate from '../components/sections/agency-soulmate';
import Vow from '../components/sections/vow';
import PartnersPlatforms from '../components/sections/partners-platforms';
import Service from '../components/sections/service';
import MissionPartners from '../components/sections/mission-partners';
import Profile from '../components/sections/profile';
import Work from '../components/sections/work';
import Certifications from '../components/sections/certifications';
import Contact from '../components/sections/contact';
import Experience from '../components/sections/experience';

const IndexPage = () => (
	<Layout>
		<SEO />
		<AgencySoulmate />
		<Vow />
		<Service />
		<PartnersPlatforms />
		<Profile />
		<Experience />
		<Work />
		<MissionPartners />
		<Certifications />
		<Contact />
	</Layout>
);

export default IndexPage;

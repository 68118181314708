import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import debounce from 'lodash/debounce';

import NavigationMobile from './navigation/navigationMobile';

import Logo from '../images/svg/admixt-logo.inline.svg';
import RadialDots from '../images/header-radial-dots.png';
import MobileDots from '../images/svg/mobile-dots.inline.svg';

function Header() {
	const onScroll = useRef(null);
	const [scroll, setScroll] = useState(false);
	const [hash, setHash] = useState(false);

	useEffect(() => {
		if (
			typeof window !== 'undefined' &&
			window.location &&
			window.location.hash
		) {
			setHash(true);
		}

		onScroll.current.ownerDocument.addEventListener(
			'scroll',
			debounce((event) => {
				setScroll(event.target.scrollingElement.scrollTop > 0);
			}, 15),
		);
	}, [scroll, hash]);

	return (
		<>
			<header
				className="fixed left-0 top-0 right-0 flex justify-center px-0 lg:px-8 overflow-hidden z-30"
				ref={onScroll}
			>
				<div className="block lg:hidden opacity-30  absolute -left-16 -top-10 transform rotate-12 w-2/5 md:w-1/5 z-10 text-white">
					<MobileDots />
				</div>

				<div
					className={`absolute top-0 left-0 transition duration-100 ease-in w-full h-full bg-gradient-to-b from-secondary-dark-teal to-primary-dark-blue ${
						scroll || hash ? 'opacity-100' : 'opacity-100 lg:opacity-0'
					}`}
				>
					<img
						className="h-full hidden lg:block"
						src={RadialDots}
						alt=""
						loading="lazy"
					/>
				</div>
				<div
					className={`hidden lg:block absolute top-0 left-0 hidden transition duration-100 ease-out w-full h-full bg-transparent ${
						scroll || hash ? 'opacity-0' : 'opacity-100'
					}`}
				/>
				<div className="relative container flex items-center py-8 z-20">
					<Link
						className="pr-2 sm:pr-0 mx-auto lg:mx-0"
						aria-label="home"
						to="/"
					>
						<Logo className="object-contain w-3/5 mx-auto lg:m-0" />
					</Link>

					<div className="hidden lg:inline-block ml-auto">
						<Link
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
							to="#services"
						>
							Services
						</Link>
						<Link
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
							to="#partners"
						>
							Partners
						</Link>
						<Link
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
							to="#profile"
						>
							Profile
						</Link>
						<Link
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
							to="#work"
						>
							Work
						</Link>
						<a
							href="https://blog.admixt.com/"
							rel="noopener noreferrer"
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
						>
							Blog
						</a>
						<a
							href="https://admixt.limesurvey.net/831597"
							rel="noopener noreferrer"
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
						>
							Quiz
						</a>
						<a
							href="https://admixt.com/corp/?action=login"
							rel="noopener noreferrer"
							className={`btn ${scroll || hash ? 'btn-white' : ''} btn-link `}
						>
							Login
						</a>
						<Link
							className={`btn ${scroll || hash ? 'btn-white' : ''}`}
							to="#contact"
						>
							Contact
						</Link>
					</div>
				</div>
			</header>
			<NavigationMobile />
		</>
	);
}

export default Header;
